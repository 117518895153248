




















































import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
import axios from '@/plugins/axios'
import MapView from '@/components/core/Map.vue';
import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";
import { Map } from "@/store/map";
import { Product } from "@/store/product";
import { App } from "@/store/app";
import {
    City
} from "@/store/city";
@Component({
    components: { MapView },
    computed: {},
})

export default class Saller extends Vue {
    farm: any = {}
    response: boolean = false;
    user: any = null
    profile: any = null
    saller: boolean = false;
    categories: any = null
    chooseCategories: any = []
    f1: any = {}
    f2: any = {}
    f3: any = {}
    f4: any = {}
    f5: any = {}
    async created() {
        await this.loadFarm()
        await this.loadChoice();
        await this.loadProduct();
        // await this.setProductKey();
    }

    async loadFarm() {
        await Core.switchLoad(true)
        this.user = await Auth.getUser()
        this.profile = await User.getProfileFull();
        this.farm = await Core.getHttp(`/api/user/farm/${this.user.pk}/`)
        await Core.switchLoad(false)
        this.response = true
    }

    public product: any = {}
    public async setProductKey() {
        //this.product.id = 0
        this.product.category = this.chooseCategories
        this.product.farm = this.farm.id
        this.product.user = this.user.pk
        this.product.status = 5
    }
    choices: any = {}
    public async loadChoice() {
        this.categories = await Core.getHttp(`/api/default/categories/`)
        this.choices = {
            "product_type": await Product.ProductType,
            "sell_type": await Product.SaleType,
            "price_type": await Product.PriceType,
            'status': await Product.StatusSell
        }
    }
    public async loadProduct() {
        let product = this.$route.query.product
        this.product = await Core.getHttp(`/api/default/product/${product}/`)
    }

    public async storeProduct() {
        await this.setProductKey()
        let store = await Core.postHttp(`/api/default/product/`, this.product)
        if (store.id) { await App.success("บันทึกประกาศสำเร็จแล้ว") }
        await this.$router.go(-1)
    }

    public async updateProduct() {
        delete this.product.file1;
        delete this.product.file2;
        delete this.product.file3;
        delete this.product.file4;
        delete this.product.file5;
        let store = await Core.putHttp(`/api/default/product/${this.product.id}/`, this.product)
        if (store.id) {
            await this.storeImage(store.id);
            await App.success("บันทึกประกาศสำเร็จแล้ว")
            await this.$router.go(-1)
        }
    }

    public async removeProduct() {
        let check = await App.confirm('คุณแน่ใจใช่ไหม', 'ที่จะลบประกาศนี้')
        if (check) {
            let store = await Core.deleteHttp(`/api/default/product/${this.product.id}/`)
            await App.error("ลบประกาศสำเร็จแล้ว")
            await this.$router.go(-1)
        }
    }

    async storeImage(id: number) {
        var formData = new FormData();
        if (this.f1.file) {
            formData.append(`file1`, this.f1.file);
        }
        if (this.f2.file) {
            formData.append(`file2`, this.f2.file);
        }
        if (this.f3.file) {
            formData.append(`file3`, this.f3.file);
        }
        if (this.f4.file) {
            formData.append(`file4`, this.f4.file);
        }
        if (this.f5.file) {
            formData.append(`file5`, this.f5.file);
        }
        await axios.put(`/api/default/productfile/${id}/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

    }

}
